import Api from "./Api";
import store from "../store/index.js";
import constants from "../constants.js";

let apiVersion = constants.apiVersion;

export default {
  updateStatus(pickingStatusp, formData, id, domSessionId) {
    let dataUrl = "";
    let retVal = null;
    const payload = {};

    payload.status = pickingStatusp;

    if (pickingStatusp === "finished") {
      payload.isPartialDelivery = formData.isPartialDelivery;
      payload.commentsPartialDelivery = encodeURIComponent(
        formData.commentsPartialDelivery
      );
      payload.packedDate = formData.packedDate;
      payload.numberOfPackages = formData.numberOfPackages;
      payload.numberOfPallets = formData.numberOfPallets;
      payload.numberOfScantainers = formData.numberOfScantainers;
      payload.parcelLocation = encodeURIComponent(formData.parcelLocation);
    }

    dataUrl = Api.dataUrl + apiVersion + "/picking-lists/" + id;

    console.log("Apis.updateStatus", dataUrl);

    retVal = Api.post(dataUrl, payload, {
      headers: {
        domSessionId,
      },
    });

    console.log("Apis.updateStatus", retVal);
    return retVal;
  },
  
  updateItem(data, id, domSessionId, pickStatus) {
    let retVal = null;
    let productNumberp = data.item.productNumber;
    productNumberp = productNumberp.replace("/", "¤");

    // if picked on bomlines the payload should contain info about parent product id and # of total bomlines.
    // Used in the LS api to register if all bomlines is picked

    let parentProductNumber = "";
    if (data.item.parentProductNumber != undefined) {
      parentProductNumber = data.item.parentProductNumber;
    }

    let locationp = data.item.defaultLocation;
    let title = encodeURIComponent(data.item.title);

    // Create payload
    let payload = {};
    payload.title = title;
    payload.quantity = data.item.quantity;
    payload.status = pickStatus;
    payload.parentProductNumber = parentProductNumber;
    payload.location = locationp

    let dataUrl = "";

    dataUrl =
      Api.dataUrl +
      apiVersion +
      "/picking-lists/" +
      id +
      "/products/" +
      productNumberp;

    console.log("Apis.updateItem dataUrl", dataUrl);
    console.log("Apis.updateItem payload", payload);

    retVal = Api.post(dataUrl, payload, {
      headers: {
        domSessionId,
      },
    });

    console.log("Apis.updateItem", retVal);
    return retVal;
  },

  getPickingList(id, domSessionId) {
    let dataUrl = "";
    let retVal = null;
    dataUrl = Api.dataUrl + apiVersion + "/picking-lists/" + id;

    console.log("Apis.getPickingList", dataUrl);

    retVal = Api.get(dataUrl, {
      headers: {
        domSessionId,
      },
    });
    console.log("Apis.getPickingList data", retVal);
    return retVal;
  },

  loadShipment(queryString, domSessionId) {
    let dataUrl = "";
    let retVal = null;
    dataUrl = Api.dataUrl + apiVersion + "/shipments" + queryString;

    console.log("Apis.loadShipment", dataUrl);

    retVal = Api.get(dataUrl, {
      headers: {
        domSessionId,
      },
    });

    console.log("Apis.loadShipment", retVal);
    return retVal;
  },

  loadStock(queryString, domSessionId) {
    let dataUrl = "";
    let retVal = null;

    dataUrl = Api.dataUrl + apiVersion + "/stock" + queryString;

    // console.log("Apis.loadStock", dataUrl);

    retVal = Api.get(dataUrl, {
      headers: {
        domSessionId,
      },
    });

    console.log("Apis.loadStock", retVal);
    return retVal;
  },

  updateStock(payload, domSessionId, queryString) {
    let dataUrl = "";
    let retVal = null;

    dataUrl = Api.dataUrl + apiVersion + "/stock" + queryString;
// eslint-disable-next-line no-debugger
debugger
    console.log("Apis.updateStockItem", dataUrl);
    console.log("Apis.updateStockItem data", payload);

    retVal = Api.post(dataUrl, payload, {
      headers: {
        domSessionId,
      },
    });

    // console.log("Apis.updateStockItem", retVal);
    return retVal;
  },

  isUserPicking(domSessionId) {
    let dataUrl = "";
    let retVal = null;
    dataUrl = Api.dataUrl + apiVersion + "/shipments" + "/mystarted";

    // console.log("Apis.isUserPicking", dataUrl);

    retVal = Api.get(dataUrl, {
      headers: {
        domSessionId,
      },
    });

    // console.log("Apis.isUserPicking", retVal);
    return retVal;
  },

  login(username, password, shopType) {
    let dataUrl = "";
    if (process.env.NODE_ENV === "development") {
      dataUrl = "http://localhost:3000/user/v1/sign-in";
    } else {
      dataUrl = store.getters.getDefaultDomainUrl + "/user/v1/sign-in";
    }

    console.log("Apis.logIn", dataUrl);

    return Api.post(
      dataUrl,
      "username=" +
        username +
        "&password=" +
        password +
        "&shoptype=" +
        shopType,
      {
        headers: {},
      }
    );
  },

  validatePincode(pincode, shopType) {
    let dataUrl = "";
    let username = store.getters.getUsername;

    if (process.env.NODE_ENV === "development") {
      dataUrl = "http://localhost:3000/user/v1/pin";
    } else {
      dataUrl = store.getters.getDefaultDomainUrl + "/user/v1/pin";
    }

    // console.log("Apis.logIn", dataUrl);

    return Api.post(
      dataUrl,
      "username=" + username + "&pincode=" + pincode + "&shoptype=" + shopType,
      {
        headers: {},
      }
    );
  },
};
