const constants = {
    // prodUrl: "https://api.metz.dk",
    // devUrl: "https://api-dev.metz.dk",
    // domainUrl : "https://api.metz.dk",
    shopType : "giftshop-webpluk",
    apiVersion: "v2",

}
    
export default constants;


