import axios from "axios";
// import constants from "../const.js"
import store from '../store/index.js';

let dataUrl = "";
if (process.env.NODE_ENV === "development") {
    dataUrl = "http://localhost:3000/giftshop-webpluk/";
  } else {
    dataUrl = store.getters.getDefaultDomainUrl
    dataUrl = dataUrl + "/giftshop-webpluk/";
  }

const Api = axios.create({
    baseURL: window.APP_API_ENDPOINT
});

Api.dataUrl = dataUrl;

export default Api;